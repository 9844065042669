<template>
  <div class="legal-list">
    <b-row>
      <b-col>
        <h2>Журнал ПОД/ФТ</h2>
      </b-col>
      <b-col class="text-right">
        <b-button to="/users/compare">Сравнение клиентов</b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card>
          <b-table :items="items" :fields="fields" hover responsive>
            <template #cell(is_negative)="{ item }">
              <template v-if="item.is_negative"> Да </template>
              <template v-else> Нет </template>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'LegalList',
  data() {
    return {
      items: [],
      fields: [
        {
          key: 'date',
          label: 'Дата операции',
        },
        {
          key: 'title',
          label: 'Событие',
        },
        {
          key: 'description',
          label: 'Описание',
        },
        {
          key: 'deal_no',
          label: 'Договор',
        },
        {
          key: 'user_fullname',
          label: 'Клиент',
        },
        {
          key: 'is_negative',
          label: 'Признана подозрительной?',
        },
      ],
    }
  },
  created() {
    this.getItems()
  },
  methods: {
    async getItems() {
      this.loading = true
      const res = await this.$http.get('legal')
      this.items = res.data.results
      this.loading = false
    },
  },
}
</script>
